import { Menu } from "lucide-react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button, buttonVariants } from "@/components/ui/button";
import { navigationMenuTriggerStyle } from "@/components/ui/navigation-menu";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { cn } from "@/lib/utils";
import { useState } from "react";
import type { User } from "@/types";
import LogoutButton from "./logout-button";
import { Input } from "./ui/input";
import { FaChartLine } from "react-icons/fa";

interface Props {
  user: User | null;
  logo: string;
  name: string;
  showSearchBar?: boolean;
}

const Navbar = ({ user, logo, name, showSearchBar = true }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cartCount, setCartCount] = useState(2);
  // TODO: move is hovered to store
  const [isHovered, setIsHovered] = useState(false);

  return (
    <header
      className="py-4 shadow bg-gradient-to-br from-[#EBC9AB] to-[#eccaac]"
      id="main-header">
      <div className="container relative">
        {/* desktop */}
        <div
          className={cn(
            "hidden lg:block transition-all ease-out duration-300 space-y-2",
            {
              "pb-2": isHovered,
            }
          )}>
          <nav className={cn("justify-between lg:flex")}>
            <div className="flex items-center gap-6">
              <a href="/" className="flex items-center gap-2">
                <img src={logo} className="w-auto h-10" alt="logo" />
                <span className="text-xl font-bold text-primary">{name}</span>
              </a>
              <div className="flex items-center">
                <a
                  className={cn(
                    "text-muted-foreground flex items-center gap-2 md:text-lg",
                    navigationMenuTriggerStyle,
                    buttonVariants({
                      variant: "ghost",
                    })
                  )}
                  href="/"
                  data-astro-prefetch>
                  Home
                </a>
                <a
                  className={cn(
                    "text-muted-foreground flex items-center gap-2 md:text-lg",
                    navigationMenuTriggerStyle,
                    buttonVariants({
                      variant: "ghost",
                    })
                  )}
                  href="/products"
                  data-astro-prefetch>
                  Products
                </a>
                {/* <a
                  className={cn(
                    "text-muted-foreground flex items-center gap-2 md:text-lg",
                    navigationMenuTriggerStyle,
                    buttonVariants({
                      variant: "ghost",
                    })
                  )}
                  href="/trending"
                  data-astro-prefetch>
                  Trending
                </a> */}
                {/* <a
                  className={cn(
                    "text-muted-foreground flex items-center gap-2 md:text-lg",
                    navigationMenuTriggerStyle,
                    buttonVariants({
                      variant: "ghost",
                    })
                  )}
                  href="/blog"
                  data-astro-prefetch>
                  News
                </a> */}
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <a href="/contact" data-astro-prefetch>
                <Button
                  variant={"ghost"}
                  className="flex items-center gap-2 md:text-lg">
                  <img
                    src="/svg/user-circle.svg"
                    alt="Profile"
                    className="h-5 w-auto"
                  />
                  <span className="">Help</span>
                </Button>
              </a>

              <a href="/cart" data-astro-prefetch className="relative">
                <Button
                  variant={"ghost"}
                  className="flex items-center gap-2 md:text-lg">
                  <img
                    src="/img/home/Cart.svg"
                    alt="Cart"
                    className="h-5 w-auto"
                  />
                  <span className="">Cart</span>
                </Button>

                {cartCount > 0 && (
                  <span className="absolute top-0 right-2 md:right-12">
                    <span className="text-[10px] text-white bg-rose-500 w-3.5 h-3.5 rounded-full grid items-center justify-center">
                      {cartCount}
                    </span>
                  </span>
                )}
              </a>

              {user ? (
                <>
                  <a href="/dashboard" data-astro-reload>
                    <Button
                      variant={"ghost"}
                      className="flex items-center gap-2 md:text-lg">
                      <img
                        src="/svg/purchases.svg"
                        alt="Purchases"
                        className="h-5 w-auto"
                      />
                      <span className="">My Purchases</span>
                    </Button>
                  </a>

                  <a href="/dashboard" data-astro-reload>
                    <Button>Dashboard</Button>
                  </a>

                  <LogoutButton />
                </>
              ) : (
                <>
                  <a href="/login" data-astro-prefetch data-astro-reload>
                    <Button variant={"outline"}>Sign In/Up</Button>
                  </a>
                  <a
                    href="/register-shop"
                    data-astro-prefetch
                    data-astro-reload>
                    <Button>Register Your Shop</Button>
                  </a>
                </>
              )}
            </div>
          </nav>

          {/* search */}
          {showSearchBar && (
            <SearchComponent
              isHovered={isHovered}
              setIsHovered={setIsHovered}
            />
          )}
        </div>

        {/* mobile */}
        <div className="block lg:hidden space-y-3">
          <div className="flex items-center justify-between">
            <a href="/" className="flex items-center gap-2">
              <img src={logo} className="h-8 w-auto" alt={name} />
              <span className="text-xl font-bold text-primary">{name}</span>
            </a>

            <a href="/cart" data-astro-prefetch className="relative">
              <Button
                variant={"ghost"}
                className="flex items-center gap-2 md:text-lg">
                <img
                  src="/img/home/Cart.svg"
                  alt="Cart"
                  className="h-5 w-auto"
                />
                <span className="sr-only">Cart</span>
              </Button>

              {cartCount > 0 && (
                <span className="absolute top-0 right-2 md:right-12">
                  <span className="text-[10px] text-white bg-rose-500 w-3.5 h-3.5 rounded-full grid items-center justify-center">
                    {cartCount}
                  </span>
                </span>
              )}
            </a>

            <Sheet open={isOpen} onOpenChange={setIsOpen}>
              <SheetTrigger asChild>
                <Button
                  variant={"outline"}
                  size={"icon"}
                  onClick={() => setIsOpen(!isOpen)}>
                  <Menu className="size-4" />
                </Button>
              </SheetTrigger>
              <SheetContent className="overflow-y-auto">
                <SheetHeader>
                  <SheetTitle>
                    <a
                      href="/"
                      data-astro-prefetch
                      className="flex items-center gap-2">
                      <img src={logo} className="h-8 w-auto" alt={name} />
                      <span className="text-xl font-bold text-primary">
                        {name}
                      </span>
                    </a>
                  </SheetTitle>
                </SheetHeader>
                <div className="my-8 flex flex-col gap-4">
                  <a href="/" className="font-semibold">
                    Home
                  </a>

                  <Accordion type="single" collapsible className="w-full">
                    <AccordionItem value="products" className="border-b-0">
                      <AccordionTrigger className="py-0 text-base font-semibold hover:no-underline">
                        Products
                      </AccordionTrigger>
                      <AccordionContent className="mt-2">
                        <a
                          className={cn(
                            "flex select-none gap-4 rounded-md p-3 leading-none outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                          )}
                          href="/products">
                          <FaChartLine className="size-6" />
                          <div>
                            <div className="text-sm font-semibold">
                              Trending
                            </div>
                            <p className="text-sm leading-snug text-muted-foreground">
                              Trending products
                            </p>
                          </div>
                        </a>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>

                  <a href="/contact" className="font-semibold">
                    Contact Us
                  </a>
                </div>
                <div className="border-t pt-4">
                  <div className="grid grid-cols-2 justify-start">
                    <a
                      className={cn(
                        buttonVariants({
                          variant: "ghost",
                        }),
                        "justify-start text-muted-foreground"
                      )}
                      href="/cart">
                      Cart
                    </a>
                    <a
                      className={cn(
                        buttonVariants({
                          variant: "ghost",
                        }),
                        "justify-start text-muted-foreground"
                      )}
                      href="/contact">
                      Help
                    </a>
                    <a
                      className={cn(
                        buttonVariants({
                          variant: "ghost",
                        }),
                        "justify-start text-muted-foreground"
                      )}
                      href="/contact">
                      Marketing
                    </a>
                    <a
                      className={cn(
                        buttonVariants({
                          variant: "ghost",
                        }),
                        "justify-start text-muted-foreground"
                      )}
                      href="/contact">
                      Advertise
                    </a>
                  </div>
                  <div className="mt-2 flex flex-col w-full gap-3">
                    <a href="/login" data-astro-prefetch>
                      <Button variant={"outline"} className="w-full block">
                        Sign in
                      </Button>
                    </a>
                    <a href="/register" data-astro-prefetch>
                      <Button className="w-full block">Sign up</Button>
                    </a>
                    <a href="/register-shop" data-astro-prefetch>
                      <Button className="w-full block" variant="ghost">
                        Register Your Shop
                      </Button>
                    </a>
                  </div>
                </div>
              </SheetContent>
            </Sheet>
          </div>

          {/* search */}
          {showSearchBar && (
            <SearchComponent
              isHovered={isHovered}
              setIsHovered={setIsHovered}
            />
          )}
        </div>
      </div>
    </header>
  );
};

const SearchComponent = ({
  setIsHovered,
  isHovered,
}: {
  isHovered: boolean;
  setIsHovered: Function;
}) => {
  const [search, setSearch] = useState("");

  const performSearch = (
    e: React.FormEvent<HTMLFormElement> | React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    // const currentUrl = window.location.pathname;
    // const isDashboardRoute =
    //   currentUrl.startsWith("/dashboard") ||
    //   currentUrl.startsWith("/shop-dashboard");

    // if (isDashboardRoute) {
    //   window.location.href = "/products?search=" + search;
    // } else {
    //   if (search?.length < 1) {
    //     $dispatch("clear");
    //   } else {
    //     Livewire.navigate("/products?search=" + search);
    //   }
    // }
  };

  return (
    <div className={cn("lg:max-w-4xl mx-auto", { "pt-2": isHovered })}>
      <form
        onSubmit={performSearch}
        className="w-full flex items-center gap-x-4 pt-0 transition-all ease-out duration-300">
        <div className="relative flex-1 group">
          <Input
            className="w-full !rounded-full pl-10 border border-gray-900 bg-white border-transparent"
            placeholder="Search for Products in the Market"
            name="search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              performSearch(e);
            }}
            onClick={() => setIsHovered(true)}
            onFocus={() => setIsHovered(true)}
            onBlur={() => setIsHovered(false)}
          />

          <div className="absolute top-0 bottom-0 left-2">
            <div className="flex h-full items-center gap-x-1 py-1.5">
              <img
                src="/svg/search.svg"
                alt="Search"
                className="h-5 w-auto fill-primary/50 group-hover:fill-primary transition"
              />

              <span className="h-full w-px bg-primary/50 group-hover:bg-primary">
                &nbsp;
              </span>
            </div>
          </div>
        </div>

        <div className="hidden md:block">
          <button
            type="submit"
            className="bg-primary px-6 py-2 rounded-full border-2 border-transparent text-white hover:text-primary hover:bg-transparent hover:border-primary transition">
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default Navbar;
